<template>
  <div class="help" v-loading="loading">
    <article-bar
      v-for="item in tableData"
      :id="item.id"
      :key="item.id"
      :time="item.time"
      :title="item.title"
    ></article-bar>
    <br />
    <div class="pagination">
      <el-pagination
        v-model:currentPage="params.page"
        :page-size="params.limit"
        background
        layout="prev, pager, next"
        :total="totalCount"
        @current-change="load"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/public'
import ArticleBar from '@/components/ArticleBar'

export default {
  name: 'index',
  components: { ArticleBar },
  data() {
    return {
      params: {
        type: 'help',
        page: 1,
        limit: 10
      },
      loading: true,
      tableData: [],
      totalCount: 0
    }
  },
  methods: {
    load() {
      console.log(new Date())
      getArticleList(this.params)
        .then((resp) => {
          console.log(new Date())
          const { documents, total } = resp.data
          this.tableData = documents
          this.totalCount = total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style scoped>
.help {
  padding: 40px;
  text-align: left;
}

.pagination {
  text-align: right;
}
</style>
