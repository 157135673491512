<template>
  <div class="article-bar">
    <h4 @click="preview"> {{ title }}</h4>
    <p>{{ datetime }}</p>
    <el-divider></el-divider>
  </div>
</template>

<script>
import {parseTime} from '@/utils'

export default {
  name: "ArticleBar",
  props: {
    id: String,
    time: Number,
    title: String,
  },
  computed: {
    datetime() {
      return parseTime(this.time * 1000)
    }
  },
  methods: {
    preview() {
      this.$router.push({path: '/preview', query: {id: this.id}})
    },
  }
}
</script>

<style lang="scss" scoped>
.article-bar {
  margin-top: 30px;

  p {
    color: #cccccc;
  }

  h4 {
    color: #666666;
    cursor:pointer;
  }

}
</style>
